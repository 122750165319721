import React, { useRef, useState } from 'react';
import Layout from '../components/layout';
import emailjs from '@emailjs/browser';
import Seo from '../components/seo';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [msg, setMsg] = useState('');

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_y8ztxxq', 'template_pzuo7la', form.current, 'kmRWxT-JuDgeNRFfC')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

        setName('');
        setEmail('');
        setPhone('');
        setMsg('');
      };

    return (
        <Layout>
            <div id='contact container' className='pt-32 flex flex-col items-center w-screen'>
                <h2 className='text-4xl text-center'>Contáctanos</h2>
                <div className='p-2 mt-8 w-4/5 md:w-1/2 md:mt-4'>
                    <p className='text-md text-center tracking-normal leading-5'>Queremos escucharte, mandanos un mensaje y cuentanos un poco más de ti.</p>
                </div>
                <div className='flex flex-col w-full md:flex-row md:justify-evenly md:w-10/12'>
                    <form 
                        className='pt-5 flex flex-col gap-5 mx-10 md:w-4/5 xl:w-2/5'
                        name='contacto'
                        ref={form}
                        onSubmit={sendEmail}
                    >
                        <div className='flex flex-row border-solid border-gitman-black border'>
                            <label className='m-1' htmlFor='input-name'>Nombre:</label>
                            <input className='w-full' name='name' type='text' value={name} onChange={event => setName(event.target.value)}/>
                        </div>
                        <div className='flex flex-row border-solid border-gitman-black border'>
                            <label className='m-1' htmlFor='input-email'>Email:</label>
                            <input className='w-full' name='email' type='email' value={email} onChange={event => setEmail(event.target.value)}/>
                        </div>
                        <div className='flex flex-row border-solid border-gitman-black border'>
                            <label className='m-1' htmlFor='input-phone'>Teléfono:</label>
                            <input className='w-full' name='phone' type='tel' value={phone} onChange={event => setPhone(event.target.value)}/>
                        </div>
                        <div className='flex flex-row border-solid border-gitman-black border'>
                            <label className='m-1' htmlFor='input-msg'>Mensaje:</label>
                            <textarea className='w-full' name='message' type='text' rows="12" value={msg} onChange={event => setMsg(event.target.value)}/>
                        </div>
                        <button className='text-gitman-white bg-gitman-green h-8' type='submit'>Enviar Mesnaje</button>
                    </form>
                    <div>
                        <div className='pt-10 flex flex-col'>
                            <h3 className='text-3xl text-center'>Teléfono</h3>
                            <p className='text-md text-center tracking-normal leading-5'>3331042002</p>
                        </div>
                        <div className='pt-10 flex flex-col'>
                            <h3 className='text-3xl text-center'>Horarios</h3>
                            <p className='text-md text-center tracking-normal leading-5'>Lunes a Sábado</p>
                        </div>
                        <div className='pt-10 flex flex-col'>
                            <h3 className='text-3xl text-center'>Email</h3>
                            <p className='text-md text-center tracking-normal leading-5'>ventas@despensatotal.com</p>
                            <p className='text-md text-center tracking-normal leading-5'>hola@clinicadelclick.com</p>
                        </div>
                        <div className='p-10 flex flex-col'>
                            <h3 className='text-3xl text-center'>Dirección</h3>
                            <p className='text-md text-center tracking-normal leading-5'>Guadalajara Jalisco</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Contact;

export function Head() {
    return(
      <Seo />
    );
  }